.ant-btn {
  line-height: 1;
}

.ant-divider-horizontal {
  margin: 0 0 24px;
}

.ant-picker {
  width: 100%;
}

.ant-input,
.ant-picker,
.ant-select.ant-select-in-form-item {
  height: 40px;
}

.ant-input-affix-wrapper {
  padding: 0 11px;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-inline-start: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-weight: 500;
}

.ant-input[disabled] {
  color: black;
}

.ant-pagination-next,
.ant-pagination-prev {
  border: 1px solid lightgray;
}
.ant-pagination .ant-pagination-options {
  display: none;
}
.ant-form-item
  .ant-form-item-control-input-content
  > .quill
  > .ql-toolbar.ql-snow {
  border-radius: 6px 6px 0 0;
}
.ant-form-item
  .ant-form-item-control-input-content
  > .quill
  > .ql-toolbar.ql-snow
  + .ql-container.ql-snow {
  border-radius: 0 0 6px 6px;
}

.ant-form-item-label > label {
  height: 100% !important;
}

.ant-tooltip {
  max-width: 360px;
}
