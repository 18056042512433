/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body,
div,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow-y: overlay;
  overflow-x: hidden;
  min-height: -webkit-fill-available;

  /* width */
  &::-webkit-scrollbar {
    width: 0;
  }
}

a,
a:hover,
a:active,
a:focus {
  color: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.ql-container {
  min-height: 300px;
}
